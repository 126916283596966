import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { SanityImage } from '@components/sanity/sanityImage';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';
import { DragCarousel } from '@components/carousels/dragCarousel';
import { PageLink } from '@components/ui/links';

export const CollectionBanner = ({ bannerImage, description, title, categoryNav }) => {
  const breakpoints = useBreakpoint();
  return (
    <div className="bg-black relative overflow-hidden">
      <div className="aspect-w-3 aspect-h-4 md:aspect-w-16 md:aspect-h-9 xl:aspect-h-7">
        <SanityImage
          image={bannerImage}
          className="absolute inset-0 object-cover opacity-50"
          width={2500}
        />
      </div>
      <div className=" absolute top-1/2 transform -translate-y-1/2 w-full px-gutter">
        <h1 className="blockH0 text-white text-center mb-one">{title}</h1>
        <PortableTextBlock
          text={description}
          className="text-white text-center max-w-4xl mx-auto"
        />
        {categoryNav && (
          <nav className="w-full pt-2">
            {breakpoints.lg ? (
              <div className="flex gap-x-4 justify-center">
                {categoryNav.items.map((item) => (
                  <PageLink
                    {...item}
                    className="btn large white flex-shrink-0"
                    activeClassName="active"
                  />
                ))}
              </div>
            ) : (
              <DragCarousel>
                <div className="flex gap-x-4">
                  {categoryNav.items.map((item) => (
                    <PageLink {...item} className="btn large white flex-shrink-0" />
                  ))}
                </div>
              </DragCarousel>
            )}
          </nav>
        )}
      </div>
    </div>
  );
};

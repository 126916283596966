import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import qs from "qs";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import FilterIcon from "@svg/filter.svg";
import CloseIcon from "@svg/close.svg";
import {
  CustomRangeSlider,
  CustomRefinementList,
  ProductHits,
  CurrentRefinements,
  CustomSearchBox,
  CustomSortBy,
  CustomStats,
} from "@components/search";
import { searchClient } from "@lib/algolia";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const CollectionFacetsDesk = () => {
  return (
    <>
      <CustomSearchBox />
      <CurrentRefinements />
      <div className="grid divide-y-2 divide-black">
        <CustomRefinementList attribute={"named_tags.brand"} label="Brands" />
        <CustomRefinementList attribute={"named_tags.type"} label="Type" />
        <CustomRangeSlider attribute="price" label="Price Range" />
      </div>
    </>
  );
};

const facetVars = {
  open: {
    opacity: 1,
    x: "0%",
  },
  closed: {
    opacity: 0,
    x: "-100%",
  },
};

const CollectionFacetsMobile = ({ setFacetsOpen, facetsOpen, title }) => {
  return (
    <motion.div
      initial="closed"
      animate={facetsOpen ? "open" : "closed"}
      variants={facetVars}
      className="w-full h-full px-nav-gutter fixed z-3000 top-0 left-0 bg-white overflow-scroll"
      transition={{ duration: 0.3, ease: "easeIn" }}
    >
      <header className="py-10 flex justify-between">
        <div>
          <h4 className="blockH3 font-medium">Filter</h4>
          <div>
            <CustomStats label={`Shop ${title}`} />
          </div>
        </div>
        <div onClick={() => setFacetsOpen(false)}>
          <CloseIcon className="w-4 text-black fill-current" />
        </div>
      </header>
      <CustomSearchBox />
      <div className="grid divide-y-2 divide-black">
        <CustomRefinementList attribute={"named_tags.brand"} label="Brands" />
        <CustomRefinementList attribute={"named_tags.type"} label="Type" />
        <CustomRangeSlider attribute="price" label="Price Range" />
      </div>
      <div className="btn" onClick={() => setFacetsOpen(false)}>
        Apply Filters
      </div>
    </motion.div>
  );
};

// Search History Boilerplate
const DEBOUNCE_TIME = 700;

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : "";

const urlToSearchState = (location) => qs.parse(location.search.slice(1));

export const CollectionGrid = ({ collectionID, title }) => {
  const breakpoints = useBreakpoint();
  const [facetsOpen, setFacetsOpen] = useState(false);

  const location = useLocation();

  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  );

  const setStateId = React.useRef();

  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function onSearchStateChange(nextSearchState) {
    clearTimeout(setStateId.current);

    setStateId.current = setTimeout(() => {
      navigate(`${searchStateToUrl(location, nextSearchState)}`, {
        replace: false,
        state: { isFilter: true },
      });
    }, DEBOUNCE_TIME);

    setSearchState(nextSearchState);
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure filters={`collection_ids:${collectionID}`} hitsPerPage={600} />
      <div className="pb-10 md:grid md:grid-cols-12 md:px-gutter lg:pt-10">
        <header className="px-nav-gutter sticky top-0 w-full bg-white py-4 border-b border-black grid grid-cols-2 z-20 md:col-start-4 md:col-end-13 md:flex md:px-none md:py-6 md:justify-between md:border-0">
          <div className="hidden md:block">
            <CustomStats label={`Shop ${title}`} />
          </div>
          <span
            className="flex gap-x-2 blockH4 font-semi-bold md:hidden"
            onClick={() => setFacetsOpen(true)}
          >
            <span>Show Filters </span>
            <FilterIcon className="w-6 h-6" />
          </span>
          <div>
            <CustomSortBy
              defaultRefinement={process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX}
              items={[
                {
                  value: process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX,
                  label: "Popular",
                },
                {
                  value: process.env.GATSBY_ALGOLIA_PRODUCTS_ASC_INDEX,
                  label: "Price low to high.",
                },
                {
                  value: process.env.GATSBY_ALGOLIA_PRODUCTS_DEC_INDEX,
                  label: "Price high to low.",
                },
              ]}
            />
          </div>
        </header>
        {/* Filtering */}
        {breakpoints.md && (
          <div className="md:col-start-1 md:col-end-4 md:row-start-1 md:row-span-2">
            <div className="sticky top-0 pt-6">
              <CollectionFacetsDesk title={title} />
            </div>
          </div>
        )}
        {/* Hits */}
        <div className="px-nav-gutter md:px-none md:col-start-5 md:col-end-13 pt-10 xl:col-start-4">
          <ProductHits />
          {/* <Pagination /> */}
        </div>
      </div>
      {!breakpoints.md && (
        <CollectionFacetsMobile
          setFacetsOpen={setFacetsOpen}
          facetsOpen={facetsOpen}
          title={title}
        />
      )}
    </InstantSearch>
  );
};

import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { useTracking } from "@lib/tracking";

import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { CollectionBanner } from "@components/collections/collectionBanner";
import { CollectionGrid } from "@components/collections/collectionGrid";

const Collection = ({ data: { page } }) => {
  const { seo, title, bannerImage, description, categoryNav, collectionID } =
    page;
  const { track } = useTracking();

  useEffect(() => {
    track("Viewed Collection", {
      collection: title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout hasTransparentHeader>
      <PageMeta {...seo} />
      <CollectionBanner
        title={title}
        bannerImage={bannerImage}
        description={description}
        categoryNav={categoryNav}
      />
      <CollectionGrid collectionID={collectionID} title={title} />
    </Layout>
  );
};

export default Collection;

export const pageQuery = graphql`
  query collectionQuery($slug: String!) {
    page: sanityCollection(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      collectionID
      bannerImage {
        ...ImageWithPreview
      }
      categoryNav {
        title
        items {
          ...PageLink
        }
      }
    }
  }
`;
